import { Form } from "react-bootstrap";
import { Tooltip } from "primereact/tooltip";
import { dsbpConstant } from "../../DSBP/constant";
import React, { useState, useEffect } from "react";
import CICInfo from "../../../assets/images/CICInfo.svg";
import CommonAutocomplete from "../../../Utils/CommonAutocomplete";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { isEqual } from "lodash";
import { Dropdown } from "primereact/dropdown";
import "./index.scss";

function CustomInput({
  key,
  src,
  alt,
  min,
  type,
  icon,
  value,
  field,
  width,
  style,
  filter,
  minDate,
  rowData,
  options,
  disabled,
  onChange,
  onSelect,
  showIcon,
  sortData,
  controlId,
  className,
  fieldType,
  filterData,
  slackValue,
  columnName,
  innerStyle,
  dateFormat,
  useOnSelect,
  optionArray,
  suggestions,
  placeholder,
  disabledDays,
  innerDisabled,
  internalField,
  innerClassName,
  dropdownMIData,
  dropDownOptions,
  contentEditable,
  wrapperClassName,
  setDropDownMatch,
  mismatchedOwnerRows,
  dropdownInitiateIDData,
}) {
  const [InputValue, setInputValue] = useState("");
  const [InitialValue, setInitialValue] = useState("");
  const [InputChanged, setInputChanged] = useState(false);
  useEffect(() => {
    setInitialValue(value);
  }, [sortData, filterData]);

  const handleChange = (options, e, field) => {
    if (fieldType === "AWM_Other_Reference") {
      const updatedText = e.target.value.replace(/[^0-9]/g, "");
      // Limit the input to 8 characters
      if (updatedText.length <= 8) {
        setInputValue(updatedText);
        if (value === updatedText) {
          setInputChanged(false);
        } else {
          setInputChanged(true);
        }
        onChange(
          updatedText,
          fieldType,
          options.DSBP_PMP_PIMaterialID,
          options,
          updatedText === InitialValue,
          InitialValue
        );
      }
    } else if (fieldType === "AWM_GroupPMP") {
      const updatedText = e.target.value;
      setInputValue(updatedText);
      if (value === updatedText) {
        setInputChanged(false);
      } else {
        setInputChanged(true);
      }
      onChange(options, e, "AWM_GroupPMP", rowData?.rowIndex, value === InitialValue, InitialValue);
    } else if (type === "EditableNumber" || type === "Toggle") {
      onChange(e, fieldType, options, rowData, value === InitialValue, InitialValue);
    } else if (type === "dropdownWithSearchAndSelect") {
      const updatedText = e?.target?.value ?? null;
      onChange(options, e, fieldType, updatedText === InitialValue, InitialValue);
    }else {
      setInputValue(e?.target?.value);
      onChange(options, e, field, rowData, value === InitialValue, InitialValue);
      if (value === e?.target?.value) {
        setInputChanged(false);
      } else {
        setInputChanged(true);
      }
    }
  };

  const handleSelect = (e, selectionType) => {
    const value = e?.target?.value ?? null;
    if (fieldType === "AWM_Supporting_PMP_Design" || fieldType === "AWM_Supporting_PMP_Layout") {
      onChange(
        value === ""
          ? ""
          : `${value}-${e?.target?.selectedOptions[0]?.getAttribute(
              "data-dropdownMIData"
            )}-${e?.target?.selectedOptions[0]?.getAttribute("data-dropdownInitiateIDData")}`,
        fieldType,
        options.DSBP_PMP_PIMaterialID,
        options,
        value === InitialValue,
        InitialValue
      );
    } else if (
      fieldType === "Role" ||
      fieldType === "Owner" ||
      fieldType === "Assignee" ||
      fieldType === "AWM_AISE" ||
      fieldType === "AWM_Biocide" ||
      fieldType === "AWM_Sellable" ||
      fieldType === "AWM_AddedToProject" ||
      fieldType === "AWM_AssemblyMechanism"
    ) {
      if (
        selectionType === "AutoSelect" &&
        (fieldType === "Role" || fieldType === "Owner" || fieldType === "Assignee")
      ) {
        onSelect(options, e, fieldType, rowData?.rowIndex, value === InitialValue, InitialValue);
      } else {
        onChange(options, e, fieldType, rowData?.rowIndex, value === InitialValue, InitialValue);
      }
    } else {
      onChange(value, fieldType, options?.DSBP_PMP_PIMaterialID, options, value === InitialValue, InitialValue);
    }
  };

  return (
    <div className="wrapper-width">
      {type === "Select" ? (
        <>
          <Form.Group style={style} disabled={disabled} controlId={controlId} className={className}>
            <Form.Select
              value={value}
              style={innerStyle}
              onChange={handleSelect}
              disabled={innerDisabled}
              placeholder={placeholder}
              className={innerClassName}
            >
              <option className="hidden-option" value="" default selected>
                Select
              </option>
              {fieldType === "AWM_GA_Brief"
                ? optionArray?.map((data) =>
                    data.File_Name === "Add GA Brief" ? (
                      <option key={data.File_Name} value={data.File_Name} style={{ color: "blue" }}>
                        {data.File_Name}
                      </option>
                    ) : (
                      <option key={data.File_Name} value={data.File_Name}>
                        {data.File_Name}
                      </option>
                    )
                  )
                : fieldType === "AWM_CIC_Needed"
                ? optionArray?.map((data) => (
                    <option key={data.code} value={data.name}>
                      {data.name}
                    </option>
                  ))
                : fieldType === "AWM_Supporting_PMP_Design"
                ? optionArray?.map((ele, index) => (
                    <option
                      value={ele}
                      key={`${ele}_${index}`}
                      data-dropdownMIData={dropdownMIData[index]}
                      data-dropdownInitiateIDData={dropdownInitiateIDData[index]}
                    >
                      {ele}
                    </option>
                  ))
                : fieldType === "AWM_Supporting_PMP_Layout"
                ? optionArray?.map((ele, index) => (
                    <option
                      value={ele}
                      key={`${ele}_${index}`}
                      data-dropdownMIData={dropdownMIData[index]}
                      data-dropdownInitiateIDData={dropdownInitiateIDData[index]}
                    >
                      {ele}
                    </option>
                  ))
                : fieldType === "AWM_AddedToProject"
                ? optionArray?.map((data) => (
                    <option key={data.code} value={data.name}>
                      {data.name}
                    </option>
                  ))
                : fieldType === "AWM_AISE"
                ? optionArray?.map((data) => (
                    <option key={data.AWM_AISE} value={data.AWM_AISE}>
                      {data.AWM_AISE}
                    </option>
                  ))
                : fieldType === "AWM_AssemblyMechanism"
                ? optionArray?.map((data) => (
                    <option key={data.code} value={data.AWM_AssemblyMechanism}>
                      {data.AWM_AssemblyMechanism || options[field]}
                    </option>
                  ))
                : fieldType === "AWM_Sellable"
                ? optionArray?.map((data) => (
                    <option key={data.code} value={data.name}>
                      {data.name}
                    </option>
                  ))
                : fieldType === "AWM_Biocide"
                ? optionArray?.map((data) => (
                    <option key={data.code} value={data.name}>
                      {data.name}
                    </option>
                  ))
                : ""}
            </Form.Select>
            {fieldType === "AWM_AddedToProject"
              ? options["RTA_POANumber"] === "" &&
                options["AWM_CICNeeded"] === "Yes" && (
                  <span>
                    <img src={CICInfo} className="cicinfoicon" alt="" />
                    <Tooltip
                      position="top"
                      target=".cicinfoicon"
                      className="tooltip-white-cic"
                      content={dsbpConstant.addToProjectDisabledMessage}
                    />
                  </span>
                )
              : null}
          </Form.Group>
        </>
      ) : type === "SeacrhAndSelectAutoComplete" ? (
        <>
          <Form.Group controlId={controlId} className={className}>
            <div className={wrapperClassName}>
              <CommonAutocomplete
                width={width}
                value={value}
                disabled={disabled}
                field={internalField}
                columnName={columnName}
                onChange={handleSelect}
                suggestions={suggestions}
                placeholder={placeholder}
                className={innerClassName}
                setDropDownMatch={setDropDownMatch}
                onSelect={(e) => (useOnSelect ? handleSelect(e, "AutoSelect") : null)}
              />
              {mismatchedOwnerRows?.includes(rowData.rowIndex) && <div>No results found</div>}
            </div>
          </Form.Group>
        </>
      ): type === "dropdownWithSearchAndSelect" ? (
        <>
          <Dropdown
            value={value}
            filter={filter}
            disabled={disabled}
            options={dropDownOptions}
            placeholder={placeholder}
            className={innerClassName}
            onChange={(e) => handleChange(options, e, field)}
          />
        </>
      ) : type === "Number" ? (
        <>
          <InputNumber
            min={min}
            disabled={disabled}
            inputId="integeronly"
            className="input-duration"
            value={options?.data?.[field]}
            onChange={(e) => {
              if (fieldType === "TaskDuration") {
                if (parseInt(options?.data?.[field]) !== parseInt(e?.target?.value)) {
                  onChange(options, e, field);
                }
              }else{
                onChange(options, e, field);
              }
            }}
            onValueChange={(e) => {
              if (fieldType === "TaskDuration") {
                if (parseInt(options?.data?.[field]) !== parseInt(e?.target?.value)) {
                  onChange(options, e, field);
                }
              }else{
                onChange(options, e, field);
              }
            }}
          />
        </>
      ) : type === "EditableNumber" ? (
        <>
          <input
            key={key}
            value={value}
            disabled={disabled}
            className={innerClassName}
            contentEditable={contentEditable}
            onChange={(e) => handleChange(options, e, field)}
          />
        </>
      ): type === "Toggle" ? (
        <>
          <img
            src={src}
            alt={alt}
            style={style}
            disabled={disabled}
            className={innerClassName}
            onClick={(e) => handleChange(options, e, field)}
          />
        </>
      ) : type === "Date" ? (
        <>
          <Calendar
            icon={icon}
            value={value}
            minDate={minDate}
            showIcon={showIcon}
            disabled={disabled}
            className={className}
            dateFormat={dateFormat}
            disabledDays={disabledDays}
            onChange={(e) => {
              if(field === "StartDate"){
                const sv = slackValue?"slackValue":"";
                const isEqualData = isEqual(InitialValue, e.target.value);
                onChange(options, field, e.target.value, sv, isEqualData);
              }else{
                onChange(options, e, field);
              }
            }}
          />
        </>
      ) : (
        <>
          <Form.Group controlId={controlId} style={{ textAlign: "-webkit-center" }}>
            <Form.Control
              onChange={(e) => handleChange(options, e, field)}
              value={InputChanged ? InputValue : value}
              className={innerClassName}
              placeholder={placeholder}
              disabled={disabled}
              style={style}
              type={type}
            />
            {options["AWM_Other_Reference"]?.length < 8 &&
              options["AWM_Other_Reference"]?.length !== 0 && (
                <div style={{ fontSize: 10, color: "red" }}>Valid PMP# is 8 digits</div>
            )}
          </Form.Group>
        </>
      )}
    </div>
  );
}

export default CustomInput;
